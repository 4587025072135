import {lock, unlock} from 'tua-body-scroll-lock';
import type {BSLOptions} from 'tua-body-scroll-lock';

const options: BSLOptions = {
  overflowType: 'clip',
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('body-scroll-lock', {
    beforeMount: (el, binding) => {
      if (binding.value) {
        lock(el, options);
      }
    },
    updated: (el, binding) => {
      if (binding.oldValue === binding.value) {
        return;
      }

      if (binding.value) {
        lock(el, options);
      } else {
        unlock(el);
      }
    },
    unmounted: (el) => {
      unlock(el);
    },
  });
});
