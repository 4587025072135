import type {FormKitNode} from '@formkit/core';
import type {FormKitValidation} from '@formkit/validation';

function isCheckboxAndRadioMultiple(node: FormKitNode) {
  return (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;
}

export function createAsteriskPlugin(node: FormKitNode) {
  node.on('created', () => {
    if (!node.props || !node.props.definition?.schema) return;

    const isRequired = node.props.parsedRules.some((rule: FormKitValidation) => rule.name === 'required');
    if (!isRequired) return;

    const isMultiOption = isCheckboxAndRadioMultiple(node);

    // if we're going to modify the schema then we need
    // to update the schemaMemoKey so we don't get an
    // invalid cached schema.
    node.props.definition.schemaMemoKey = `required_${isMultiOption ? 'multi_' : ''}${node.props.definition.schemaMemoKey}`;

    const schemaFn = node.props.definition.schema;

    if (typeof schemaFn !== 'function') {
      return;
    }

    node.props.definition.schema = (sectionsSchema = {}) => {
      if (isRequired) {
        if (isMultiOption) {
          sectionsSchema.legend = {
            children: ['$label', '*'],
          };
        } else {
          sectionsSchema.label = {
            children: ['$label', '*'],
          };
        }
      }

      return schemaFn(sectionsSchema);
    };
  });
}
