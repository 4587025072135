import {nl} from '@formkit/i18n';
import {createConfig} from '@formkit/core';
import {generateClasses} from '@formkit/themes';
import {createProPlugin, repeater} from '@formkit/pro-unlimited';
import {createFloatingLabelsPlugin} from '@formkit/addons';
import {formKitIcons} from '~/utils/icon';
import {createAsteriskPlugin} from '~/utils/formkit-asterisk';

const pro = createProPlugin('fk-8243d9e8fb', {repeater});

export default createConfig({
  locales: {nl},
  locale: 'nl',
  plugins: [
    pro,
    createAsteriskPlugin,
    createFloatingLabelsPlugin({
      useAsDefault: false,
    }),
  ],
  icons: {
    ...formKitIcons,
  },
  config: {
    classes: generateClasses({
      // TODO: 1) Implement floatingLabel for select
      global: {
        help: 'text-xs text-gray',
        message: 'text-xs text-error',
        inner: 'formkit-floating-label:relative',
        input: 'formkit-floating-label:bg-dynamic formkit-floating-label:relative peer formkit-floating-label:text-white formkit-floating-label:placeholder:text-white',
        legend: 'font-semibold',
        label: `
          transition-all delay-100 duration-200 ease-out
          formkit-floating-label:bg-dynamic formkit-floating-label:absolute formkit-floating-label:left-3 formkit-floating-label:z-10
          formkit-floating-label:m-0 formkit-floating-label:p-1
          formkit-floating-label:top-1/2 formkit-floating-label:-translate-y-1/2
          formkit-floating-label:text-white
          formkit-floating-label:peer-focus:text-sm formkit-floating-label:peer-focus:top-0 formkit-floating-label:peer-focus:delay-0
          formkit-floating-label:formkit-not-empty:text-sm formkit-floating-label:formkit-not-empty:top-0 formkit-floating-label:formkit-not-empty:delay-0
        `,
      },
      form: {
        messages: 'bg-white rounded-lg p-3 mt-6',
      },
      'family:box': {
        wrapper: 'flex items-center mb-1 cursor-pointer',
        help: 'mb-2',
        outer: 'mb-6',
        options: 'list-none list-image-none m-0 ps-0',
        option: 'm-0 p-0',
        input: 'form-check-input appearance-none h-5 w-5 mr-2 border border-gray-lightest rounded-sm bg-white hover:cursor-pointer checked:bg-yellow checked:focus:bg-yellow checked:hover:bg-yellow focus:ring-transparent transition duration-200',
        label: 'font-normal mt-1',
      },
      'family:button': {
        input: 'btn btn-sm btn-primary lg:btn-lg',
      },
      'family:text': {
        outer: 'mb-6',
        label: 'font-medium mb-1',
        input: 'w-full rounded-lg border border-gray-lightest py-4 focus:ring-yellow ring-offset-yellow focus:border-yellow placeholder:text-gray-lighter',
      },
      textarea: {
        outer: 'mb-6',
        label: 'font-medium mb-1',
        input: 'w-full rounded-lg border border-gray-lightest py-4 focus:ring-yellow ring-offset-yellow focus:border-yellow placeholder:text-gray-lighter',
      },
      select: {
        outer: 'mb-6',
        label: 'font-medium',
        input: 'w-full rounded-lg border border-gray-lightest py-3 lg:py-4 focus:ring-yellow ring-offset-yellow focus:border-yellow',
      },
      file: {
        wrapper: 'flex items-center relative',
        outer: 'card-base bg-white/10 px-6 py-8 lg:py-6',
        inner: '',
        label: 'text-white flex-grow',
        input: 'absolute inset-0 z-10 opacity-0',
        noFiles: 'rounded-lg text-dynamic py-2.5 px-2 pointer-events-none',
        fileRemove: 'ml-4 text-error',
      },
      repeater: {
        legend: 'text-xl',
        controls: 'flex flex-col items-center gap-y-2',
        item: 'flex justify-between gap-x-2 items-center',
        content: 'flex gap-x-2',
        remove: 'btn btn-secondary px-4 py-4 text-center cursor-pointer',
        down: 'btn btn-icon px-4 py-2 text-center cursor-pointer text-[0px]',
        up: 'btn btn-icon px-4 py-2 text-center cursor-pointer text-[0px]',
      },
    }),
  },
});
