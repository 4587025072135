import validate from "/data/www/westerduin.nl/westerduin/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/westerduin.nl/westerduin/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.3_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_twienvrejjueyxth3y7tiyjytq/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/westerduin.nl/westerduin/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/westerduin.nl/westerduin/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.2__magi_eqkcnc55joq54ffumsfadii7ji/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts")
}