import { default as _91_46_46_46slug_93XzFxsaxP4RMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/[...slug].vue?macro=true";
import { default as _404HLJphQqobuMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/404.vue?macro=true";
import { default as indexxL9lLyZNJlMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/arbochecklist/index.vue?macro=true";
import { default as bedanktysYYJ3nUjdMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/bedankt.vue?macro=true";
import { default as _91slug_93iGO6A86noaMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/contact/[slug].vue?macro=true";
import { default as indexjq78fmyljDMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/contact/index.vue?macro=true";
import { default as indexWcQ4gausgSMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/index.vue?macro=true";
import { default as _91slug_9389rDzv1eQGMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/nieuws/[slug].vue?macro=true";
import { default as indexetEi4xf50cMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/nieuws/index.vue?macro=true";
import { default as open_45sollicitatiewgSt0xBGssMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/open-sollicitatie.vue?macro=true";
import { default as _91_46_46_46slug_93VAtro7KKC4Meta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/over-westerduin/[...slug].vue?macro=true";
import { default as _91slug_93KVIPWMxLyKMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/referenties/[slug].vue?macro=true";
import { default as indexYuB6RGQwvNMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/referenties/index.vue?macro=true";
import { default as vacatures_45als_45_91slug_93RdrfaXu1dgMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures-als-[slug].vue?macro=true";
import { default as vacatures_45bij_45_91slug_93LEW2C1aywjMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures-bij-[slug].vue?macro=true";
import { default as vacatures_45in_45_91slug_93ZURl5OQGRhMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures-in-[slug].vue?macro=true";
import { default as _91slug_93JSoy8KwvmgMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures/[slug].vue?macro=true";
import { default as indexVfce4fUC7lMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures/index.vue?macro=true";
import { default as vragenXk7YhRFXjJMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/vragen.vue?macro=true";
import { default as _91_46_46_46slug_93baweEIwDYVMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/werkgevers/[...slug].vue?macro=true";
import { default as bedanktggKpfEX5CzMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/werkgevers/bedankt.vue?macro=true";
import { default as faqxijndwxqh9Meta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/werkgevers/faq.vue?macro=true";
import { default as registratie_45inlenersbeloningheoC7Lxd58Meta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/werkgevers/registratie-inlenersbeloning.vue?macro=true";
import { default as werkwijzeN2eIKbHpauMeta } from "/data/www/westerduin.nl/westerduin/nuxt/pages/werkwijze.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93XzFxsaxP4RMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "arbochecklist",
    path: "/arbochecklist",
    meta: indexxL9lLyZNJlMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/arbochecklist/index.vue").then(m => m.default || m)
  },
  {
    name: "bedankt",
    path: "/bedankt",
    meta: bedanktysYYJ3nUjdMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: "contact-slug",
    path: "/contact/:slug()",
    meta: _91slug_93iGO6A86noaMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/contact/[slug].vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexjq78fmyljDMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexWcQ4gausgSMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "nieuws-slug",
    path: "/nieuws/:slug()",
    meta: _91slug_9389rDzv1eQGMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/nieuws/[slug].vue").then(m => m.default || m)
  },
  {
    name: "nieuws",
    path: "/nieuws",
    meta: indexetEi4xf50cMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/nieuws/index.vue").then(m => m.default || m)
  },
  {
    name: "open-sollicitatie",
    path: "/open-sollicitatie",
    meta: open_45sollicitatiewgSt0xBGssMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/open-sollicitatie.vue").then(m => m.default || m)
  },
  {
    name: "over-westerduin-slug",
    path: "/over-westerduin/:slug(.*)*",
    meta: _91_46_46_46slug_93VAtro7KKC4Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/over-westerduin/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "referenties-slug",
    path: "/referenties/:slug()",
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/referenties/[slug].vue").then(m => m.default || m)
  },
  {
    name: "referenties",
    path: "/referenties",
    meta: indexYuB6RGQwvNMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/referenties/index.vue").then(m => m.default || m)
  },
  {
    name: "vacatures-als-slug",
    path: "/vacatures-als-:slug()",
    meta: vacatures_45als_45_91slug_93RdrfaXu1dgMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures-als-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-bij-slug",
    path: "/vacatures-bij-:slug()",
    meta: vacatures_45bij_45_91slug_93LEW2C1aywjMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures-bij-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-in-slug",
    path: "/vacatures-in-:slug()",
    meta: vacatures_45in_45_91slug_93ZURl5OQGRhMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures-in-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-slug",
    path: "/vacatures/:slug()",
    meta: _91slug_93JSoy8KwvmgMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures",
    path: "/vacatures",
    meta: indexVfce4fUC7lMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/vacatures/index.vue").then(m => m.default || m)
  },
  {
    name: "vragen",
    path: "/vragen",
    meta: vragenXk7YhRFXjJMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/vragen.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-slug",
    path: "/werkgevers/:slug(.*)*",
    meta: _91_46_46_46slug_93baweEIwDYVMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/werkgevers/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-bedankt",
    path: "/werkgevers/bedankt",
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/werkgevers/bedankt.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-faq",
    path: "/werkgevers/faq",
    meta: faqxijndwxqh9Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/werkgevers/faq.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-registratie-inlenersbeloning",
    path: "/werkgevers/registratie-inlenersbeloning",
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/werkgevers/registratie-inlenersbeloning.vue").then(m => m.default || m)
  },
  {
    name: "werkwijze",
    path: "/werkwijze",
    component: () => import("/data/www/westerduin.nl/westerduin/nuxt/pages/werkwijze.vue").then(m => m.default || m)
  }
]