<template>
  <NuxtLayout name="default">
    <div class="container">
      <div class="card-base p-20">
        <h1 class="h1">
          {{ error.statusCode }} - {{ error.statusCode === 404 ? `Pagina bestaat niet` : error.message }}
        </h1>
        <div class="prose">
          <p>Helaas deze pagina bestaat niet meer.</p>
          <NuxtLink :to="{name: 'index'}">
            Naar de homepage
          </NuxtLink>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

onMounted(() => console.error(props.error));

useHead({
  title: 'Pagina niet gevonden',
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Westerduin` : 'Westerduin';
  },
  link: [
    {rel: 'stylesheet', href: 'https://use.typekit.net/qrw2yuj.css'},
  ],
});
</script>
