<template>
  <NuxtLayout
    :name="layout"
    class="overflow-clip"
  >
    <NuxtPage id="page" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import emitter from 'tiny-emitter/instance';

const page = useSofiePageWhenAvailable();

const layout = computed(() => {
  if (page.value?.template_name === 'TemplateUitgebreid') {
    return 'extended';
  } else {
    return 'default';
  }
});

useHead({
  title: page.value?.title ?? '',
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Westerduin` : 'Westerduin';
  },
  meta: [
    {name: 'og:site_name', content: 'Westerduin'},
    {name: 'og:image', content: '/icon.png'},
  ],
  link: [
    {rel: 'stylesheet', href: 'https://use.typekit.net/qrw2yuj.css'},
  ],
});

const color = ref('#7270C2');
useRouter().beforeResolve((to, from) => {
  if (to.path !== from.path) {
    color.value = '#7270C2';
  }
});

// TODO: use some sort of page meta instead of dynamic style component
emitter.on('global:set-color', (newColor: string) => {
  // Only change it if the new color isn't null, otherwise keep default violet color
  if (newColor) {
    color.value = newColor;
  }
});
</script>

<style scoped>
#page {
  --dynamic: v-bind(color) !important;
}
</style>
