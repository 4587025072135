/**
 * Makes a --dvw CSS property available, which is 1% of the viewport width. This differs from the (d)vw units in that it
 * takes into account space occupied by the browser scrollbar.
 */
export default defineNuxtPlugin(() => {
  function update() {
    document.documentElement.style.setProperty(
      '--dvw',
      `${document.documentElement.clientWidth / 100}px`,
    );
  }

  window.addEventListener('resize', update);
  update();
});
